import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { mapStateToProps } from "@/store";

export default class Toast extends PureComponent {
    render() {
        const { isShowToast, tstmsg, delay = 1200, dispatch } = this.props;

        !!isShowToast &&
            setTimeout(() => {
                dispatch({
                    type: "DELETE_TOAST",
                });
            }, delay);
        return (
            !!isShowToast && (
                <div className="mod-toast">
                    <span className="icon-loading"></span>
                    <span className="txt">{tstmsg}</span>
                </div>
            )
        );
    }
}

Toast = connect(mapStateToProps)(Toast);
