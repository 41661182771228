import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { mapStateToProps } from "@/store";
import { queryLogin } from "@/apis";
import { store } from "@/utils";
import "@/components/styles/login.scss";

class Login extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loging: "",
            btnDisabled: false,
            logintxt: "登录",
            username: "",
            password: "",
        };
        this.handleInput.bind(this);
        this.handleLogin.bind(this);
    }
    componentDidMount() {
        if (store.get("userInfo")) {
            const userInfo = JSON.parse(store.get("userInfo"));
            this.setState({
                username: userInfo.username,
            });
        }
        setTimeout(() => {
            this.props.setHeadFix(false, "");
        }, 50);
    }
    async handleLogin() {
        const { username, password } = this.state;
        const { dispatch, history } = this.props;

        if (username === "") {
            return dispatch({
                type: "SHOW_TOAST",
                tstmsg: "用户名不能为空",
            });
        }
        if (password === "") {
            return dispatch({
                type: "SHOW_TOAST",
                tstmsg: "密码不能为空",
            });
        }

        this.setState({
            logintxt: "稍等…",
            loging: "loging",
            btnDisabled: true,
        });
        try {
            const loginRet = await queryLogin({
                username,
                password,
            });

            if (!loginRet.code || loginRet.code !== 200) {
                this.setState({
                    loging: "",
                    btnDisabled: false,
                    logintxt: "登录",
                });
                return dispatch({
                    type: "SHOW_TOAST",
                    tstmsg: loginRet.msg || "错误~",
                });
            }
            store.set(
                "userInfo",
                JSON.stringify({
                    username: loginRet.username,
                })
            );
            store.set("token", loginRet.token);
            dispatch({
                type: "SHOW_TOAST",
                tstmsg: "登录成功 :)",
            });

            setTimeout(() => {
                history.push("/dashboard");
            }, 1200);
        } catch (err) {
            console.error(err);
            dispatch({
                type: "SHOW_TOAST",
                tstmsg: JSON.stringify(err) || "错误~",
            });
        }
    }
    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }
    render() {
        const { username, password, logintxt, loging, btnDisabled } =
            this.state;
        const avatar = this.props?.system?.avatar;
        return (
            <section className="page-login">
                <span className="avatar">
                    <img
                        src={
                            avatar ||
                            "//static.eyan.life/tang/95BA235D3C3BB6ECAE6EF7EDEC1A8035.jpg?imageMogr2/auto-orient/thumbnail/160x/strip/quality/70"
                        }
                        alt="易言"
                    />
                </span>
                <ul className="mod-login">
                    <li className="item">
                        <input
                            type="text"
                            name="username"
                            placeholder="帐号"
                            data-lpignore="true"
                            value={username}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </li>
                    <li className="item">
                        <input
                            type="password"
                            name="password"
                            placeholder="密码"
                            data-lpignore="true"
                            value={password}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </li>
                    <li className="item">
                        <button
                            type="submit"
                            className={loging}
                            disabled={btnDisabled}
                            onClick={() => this.handleLogin()}
                        >
                            {logintxt}
                        </button>
                    </li>
                </ul>
            </section>
        );
    }
}

Login.propTypes = {
    loging: PropTypes.string,
    btnDisabled: PropTypes.bool,
    logintxt: PropTypes.string,
    username: PropTypes.string,
    password: PropTypes.string,
};

export default Login = withRouter(connect(mapStateToProps)(Login));
