import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { mapStateToProps } from "@/store";
import { requireScript, store } from "@/utils";
import { Logout } from "@/apis";
import "@/components/Dashboard/style.scss";
import { Tabs, TabsContent } from "@/components/Dashboard/index";

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabcont: 1, // 显示的tab内容
        };
        this.checkUser();
    }
    checkUser() {
        const { history } = this.props;
        if (!store.get("token") || !store.get("userInfo")) {
            console.warn("没有登录");
            history.push({
                pathname: "/login",
                query: {
                    ref: history.location.pathname,
                },
            });
        }
    }
    async componentDidMount() {
        setTimeout(() => {
            this.props.setHeadFix(true, "pushment");
        }, 50);
        await requireScript("/assets/jweixin-1.1.0.js");
    }
    async setTab(idx) {
        if (idx === 3) {
            if (window.confirm("确定要退出吗？")) {
                try {
                    let logret = await Logout();
                    if (logret.code === 200 && logret.msg) {
                        console.warn("退出登录成功");
                        store.remove("token");
                        window.location.href = "/";
                    }
                } catch (err) {
                    console.error(err);
                }
            }
            return window.history.back(-1);
        }
        this.setState({
            tabcont: idx,
        });
    }
    render() {
        return (
            <>
                <Tabs
                    cont={this.state.tabcont}
                    setTab={this.setTab.bind(this)}
                />
                <TabsContent
                    cont={this.state.tabcont}
                    dispatch={this.props.dispatch}
                />
            </>
        );
    }
}

Dashboard = withRouter(connect(mapStateToProps)(Dashboard));
