/**
 * 简单封装mapState和mapProp
 */

export const mapStateToProps = (state) => {
    if (!state) return {};

    return { ...state };
};

export const mapDispatchToProps = (dispatch) => {
    /** 定义了一个func后可以在connect第二个参数prop给组件用 */
    return {
        // updateDaily: (data) => {
        //     dispatch({
        //         type: 'UPDATE_DAILY',
        //         dailylist: data
        //     })
        // },
        // showToast: (msg) => {
        //     dispatch({
        //         type: 'SHOW_TOAST',
        //         tstmsg: msg
        //     })
        // },
        // closeToast: (msg) => {
        //     dispatch({
        //         type: 'DELETE_TOAST'
        //     })
        // },
    };
};
