import coordtransform from "coordtransform";
import store from "./store";
import fetchJsonp from "fetch-jsonp";
import Config from "@/config";

const closeToast = (role, time) => {
    let _time = time || 1000;
    setTimeout(() => {
        role.toast = false;
        role.tstmsg = null;
    }, _time);
};

const getLocalTime = (dateTimeStamp) => {
    let _time = new Date(dateTimeStamp); // 2018-01-12T21:36:20.202Z =>  UTC时间

    return {
        _date:
            numberfix(_time.getUTCMonth() + 1) +
            "/" +
            numberfix(_time.getUTCDate()),
        _year: _time.getUTCFullYear(),
    };
};

const numberfix = (num) => {
    return Number(num) < 10 ? "0" + num : num;
};

const imgProtocalTeck = (src) => {
    // 如果网址没有http或者https，就根据当前的protocol返回完整img地址
    return src.indexOf("http") === -1 ? window.location.protocol + src : src;
};

const getDateDiff = (dateTimeStamp) => {
    let minute = 1000 * 60;
    let hour = minute * 60;
    let day = hour * 24;
    // let halfamonth = day * 15;
    let month = day * 30;
    let now = new Date().getTime();
    let diffValue = now - dateTimeStamp;
    if (diffValue < 0) return;

    let monthC = diffValue / month;
    let weekC = diffValue / (7 * day);
    let dayC = diffValue / day;
    let hourC = diffValue / hour;
    let minC = diffValue / minute;
    let result = "";
    if (monthC >= 1) {
        result += parseInt(monthC) + "月前";
    } else if (weekC >= 1) {
        result += parseInt(weekC) + "周前";
    } else if (dayC >= 1) {
        result += parseInt(dayC) + "天前";
    } else if (hourC >= 1) {
        result += parseInt(hourC) + "小时前";
    } else if (minC >= 1) {
        result += parseInt(minC) + "分钟前";
    } else result = "刚刚";
    return result;
};

const requireScript = (src) => {
    return new Promise((resolve, reject) => {
        try {
            if (!src) reject("src地址为空");
            if (document.getElementById(src)) {
                return resolve();
            }
            const script = document.createElement("script");
            script.src = src;
            script.async = true;
            script.id = src;
            script.onload = script.onreadystatechange = function () {
                console.log(src + "JS loaded");
                resolve();
            };
            document.body.appendChild(script);
        } catch (err) {
            console.error(err);
            reject(err);
        }
    });
};

const isIphonex = () => {
    // X XS, XS Max, XR
    const xSeriesConfig = [
        {
            devicePixelRatio: 3,
            width: 375,
            height: 812,
        },
        {
            devicePixelRatio: 3,
            width: 414,
            height: 896,
        },
        {
            devicePixelRatio: 2,
            width: 414,
            height: 896,
        },
    ];
    // h5
    if (typeof window !== "undefined" && window) {
        const isIOS = /iphone/gi.test(window.navigator.userAgent);
        if (!isIOS) return false;
        const { devicePixelRatio, screen } = window;
        const { width, height } = screen;
        return xSeriesConfig.some(
            (item) =>
                item.devicePixelRatio === devicePixelRatio &&
                item.width === width &&
                item.height === height
        );
    }
    return false;
};

/**
 * 七牛bucket接口，根据存储内容获取exif信息
 * @param {String} url 七牛地址，只能是媒体
 */
const getExif = (url) => {
    return new Promise((resolve, reject) => {
        fetch(`${url}?exif`)
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
                console.error("Error:", error);
            });
    });
};

/**
 * 七牛bucket接口，根据存储内容获取图片颜色平均值
 * https://developer.qiniu.com/dora/1268/image-average-hue-imageave
 * @param {String} url 七牛地址，只能是图片
 */
const getImageAve = (url) => {
    return new Promise((resolve, reject) => {
        fetch(`${url}?imageAve`)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    data.RGB = null;
                } else {
                    data.code = 200;
                    data.RGB = `#${data.RGB.slice(2)}`;
                }
                resolve(data);
            })
            .catch((error) => {
                reject(error);
                console.error("Error:", error);
            });
    });
};

/**
 * 转换七牛kodo的gps坐标
 */
const tfCoord = (data) => {
    let olat = data.GPSLatitude.val.split(", ");
    let olng = data.GPSLongitude.val.split(", ");
    let lat = 0,
        lng = 0;
    for (let i = 0; i < olat.length; i++) {
        lat += olat[i] / Math.pow(60, i);
        lng += olng[i] / Math.pow(60, i);
    }
    lat = data.GPSLatitudeRef.val == "S" ? -lat : lat;
    lng = data.GPSLongitudeRef.val == "W" ? -lng : lng;
    return coordtransform.wgs84togcj02(lng, lat);
};

/**
 * 根据度分秒转换成经纬度
 * 坐标是以度为单位的，东经北纬为正值，西经南纬为负值。这里需要将度分秒的经纬度转换以度为单位的，因度分秒之间为 60 进制，故转换公式为：
 * 度分秒 = 度 + 分 / 60 + 秒 / (60 * 60)
 * @param {int} degree 度
 * @param {int} minute 分
 * @param {int} second 秒
 */
const getCoord = (degree, minute, second) => {
    return degree + minute / 60 + second / (60 * 60);
};

/**
 * 根据坐标转换成详细地址
 * 腾讯地图sdk https://lbs.qq.com/service/webService/webServiceGuide/webServiceGcoder
 * ex: https://apis.map.qq.com/ws/geocoder/v1/?output=jsonp&location=23.290412836766404,113.30051091651734&get_poi=1&key=7QJBZ-N4N6J-ALPFH-FRUPA-MOAQO-XAFTX
 * @param {int} longitude 经度
 * @param {int} latitude 纬度
 */
const getAddressByCoord = (longitude, latitude) => {
    return new Promise((resolve, reject) => {
        if (process.env.NODE_ENV === "development") {
            console.warn("不支持调试白名单，MOCK GPS地理位置信息");
            return resolve(require("@/mock/gps.json")); // 本地mock返回一些数据
        }
        fetchJsonp(
            `https://apis.map.qq.com/ws/geocoder/v1/?output=jsonp&location=${latitude},${longitude}&get_poi=1&key=${Config.qqMapKey}`,
            {}
        )
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
                console.error("Error:", error);
            });
    });
};

/**
 * 获取url参数
 * @param {String} string 字符串/url
 */
const getURLParameters = (string) => {
    return (
        string
            .match(/([^?=&]+)(=([^&]*))/g)
            // eslint-disable-next-line
            .reduce(
                (array, value) => (
                    (array[value.slice(0, value.indexOf("="))] = value.slice(
                        value.indexOf("=") + 1
                        // eslint-disable-next-line
                    )),
                    array
                ),
                {}
            )
    );
};

/**
 * 判断字符串返回是不是对象，AVOS存储一些字段是JSON.stringify进去的
 */
const isJsonString = (str) => {
    if (typeof str == "string") {
        try {
            const obj = JSON.parse(str);
            if (typeof obj == "object" && obj) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }
    return false;
};

/**
 * 获取文件缩略图，如果是input，就是files[0]
 */
const fileToThumb = (file) => {
    const a = new FileReader();
    a.onload = function (e) {
        var base64Str = e.target.result; //获取base64
        var base64Code = base64Str.substring(base64Str.indexOf(",") + 1);

        return base64Code;
    };
    a.readAsDataURL(file);
};

export {
    store,
    getDateDiff,
    imgProtocalTeck,
    numberfix,
    getLocalTime,
    closeToast,
    requireScript,
    isIphonex,
    getURLParameters,
    getExif,
    getImageAve,
    getCoord,
    getAddressByCoord,
    tfCoord,
    isJsonString,
    fileToThumb,
};
