import React, { PureComponent, Component } from "react";
import { withRouter } from "react-router-dom";
import viewer from "react-mobile-image-viewer";
import "react-mobile-image-viewer/lib/index.css";
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromRaw,
    convertFromHTML,
} from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createHashtagPlugin from "@draft-js-plugins/hashtag";
import "@draft-js-plugins/linkify/lib/plugin.css";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import "@draft-js-plugins/hashtag/lib/plugin.css";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    CodeButton,
    HeadlineOneButton,
    HeadlineTwoButton,
    HeadlineThreeButton,
    BlockquoteButton,
} from "@draft-js-plugins/buttons";
import config from "@/config";
import {
    tfCoord,
    getExif,
    getImageAve,
    getAddressByCoord,
    requireScript,
    store,
    getURLParameters,
    imgProtocalTeck,
    isJsonString,
    fileToThumb,
} from "@/utils";
import {
    getPrefop,
    queryCates,
    setCates,
    setPost,
    updatePost,
    queryPfop,
    queryDailyItem,
} from "@/apis";
import { BtnUpload } from "./Button";
import { Radio, Toast, Form } from "react-vant";

// #TODO 点击H标题还需要更新editorState并存储到本地store，否则状态丢失
class HeadlinesPicker extends Component {
    componentDidMount() {
        setTimeout(() => {
            window.addEventListener("click", this.onWindowClick);
        });
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.onWindowClick);
    }

    onWindowClick = () => this.props.onOverrideContent(undefined);

    render() {
        const buttons = [
            HeadlineOneButton,
            HeadlineTwoButton,
            HeadlineThreeButton,
        ];
        return (
            <>
                {buttons.map((Button, i) => (
                    // eslint-disable-next-line
                    <Button key={i} {...this.props} />
                ))}
            </>
        );
    }
}

class HeadlinesButton extends Component {
    onClick = () => this.props.onOverrideContent(HeadlinesPicker);

    render() {
        return (
            <div className="headlineButtonWrapper">
                <button onClick={this.onClick} className="headlineButton">
                    H
                </button>
            </div>
        );
    }
}

const linkifyPlugin = createLinkifyPlugin({ target: "_blank" });
const toolbarPlugin = createToolbarPlugin();
const hashtagPlugin = createHashtagPlugin();
const { Toolbar } = toolbarPlugin;
const plugins = [hashtagPlugin, linkifyPlugin, toolbarPlugin];
class AddrList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            addrActive: -1,
        };
    }
    handleAddr(item, idx) {
        const { addr } = this.props;
        this.setState({
            addrActive: idx,
        });
        addr(item);
    }
    render() {
        const { list } = this.props;
        return (
            <ul className="addr-recommend">
                {list.map((item, index) => {
                    return (
                        <li
                            onClick={this.handleAddr.bind(this, item, index)}
                            className={`item ${
                                index === this.state.addrActive ? "active" : ""
                            }`}
                            key={item.id}
                            index={index}
                            data-id={item.id}
                            data-address={item.address}
                        >
                            <em>{item.title}</em>
                        </li>
                    );
                })}
            </ul>
        );
    }
}

class WaveLoading extends PureComponent {
    render() {
        const { loading = 0 } = this.props;
        if (loading === 0) return "";

        return (
            <section className="wave-wrap">
                <div
                    className="wave-animation-water"
                    style={{
                        transform: `translate(0, calc(100% - ${loading}%))`,
                    }}
                >
                    <svg viewBox="0 0 560 20" className="mod-waw waw-back">
                        <path d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z"></path>
                        <path d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z"></path>
                        <path d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z"></path>
                        <path d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z"></path>
                    </svg>
                    <svg viewBox="0 0 560 20" className="mod-waw waw-front">
                        <path d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z"></path>
                        <path d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z"></path>
                        <path d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z"></path>
                        <path d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z"></path>
                    </svg>
                </div>
            </section>
        );
    }
}

class Publish extends Component {
    constructor(props) {
        super(props);
        const Initxt = store.get("postRawTxt")
            ? EditorState.createWithContent(
                  convertFromRaw(JSON.parse(store.get("postRawTxt")))
              )
            : EditorState.createEmpty();
        this.state = {
            postxt: Initxt, // 编辑器init，需要发布的文字内容
            addrList: [], // 缓存相片地点，相片exif有gps信息就会展示地点
            addrGps: {}, // 最终相片exif定位信息，默认是addrList第一个object
            editState: false, // 这个是编辑状态
            editId: null, // 编辑状态才有的id
            upimgState: false,
            uploadPercent: 0,
            upStateTxt: "",
            imglist: [],
            imgListLen: 0, // 添加到上传队列的个数，控制上传触发
            role: 1, // 发布的角色
            rolelist: {
                // TODO: 读取系统设置，而且编辑状态下不准
                1: "牛粑粑",
                2: "牛麻麻",
            },
            watcher: 1,
            watcherList: {
                // TODO: 读取系统设置
                1: {
                    name: "奕言",
                    avatar: "/rhea/avatar-school.jpg?imageMogr2/auto-orient/thumbnail/300/strip/quality/90",
                },
                2: {
                    name: "可言",
                    avatar: "/avatar/logo-yan.jpg?imageMogr2/auto-orient/thumbnail/300/strip/quality/90",
                },
                3: {
                    name: "卓言",
                    avatar: "/avatar/avatar-pizza.jpg?imageMogr2/auto-orient/thumbnail/40/strip/quality/90",
                },
            },
            catesList: [],
        };
    }
    componentWillUnmount() {
        // 组建卸载后销毁定时器
        clearInterval(this.state.intervalFpof);
        this.setState({
            intervalFpof: null,
        });
    }
    async componentDidMount() {
        this.initQiniu();
        this.routerHandle(this.props.location);
        const { dispatch } = this.props;
        const { catesId = "" } = this.state;
        try {
            if (store.get("pfopStatus")) {
                const id = JSON.parse(store.get("pfopStatus")).id;
                this.setState({
                    upimgState: true,
                    upStateTxt: "转换中...",
                });
                this.checkResult(id);
            }
            store.get("role") &&
                this.setState({
                    role: store.get("role"),
                });
            store.get("postMedia") &&
                this.setState({
                    imglist: JSON.parse(store.get("postMedia")),
                    imgListLen: JSON.parse(store.get("postMedia")).length,
                });
            store.get("gpsList") &&
                this.setState({
                    addrList: JSON.parse(store.get("gpsList")),
                });
            const { data: CATELIST } = await queryCates();
            let catesIndex = 0;
            !!catesId &&
                CATELIST.forEach((cate, idx) => {
                    if (catesId === cate.objectId) {
                        catesIndex = idx;
                    }
                });
            this.setState({
                catesList: CATELIST,
                catesIndex: catesIndex,
                catesId: !!catesId ? catesId : CATELIST[0].objectId,
            });
        } catch (err) {
            console.error(err);
            dispatch({
                type: "SHOW_TOAST",
                tstmsg: `错误： ${err}`,
            });
        }
    }
    handleChange(val) {
        this.setState({
            postxt: val,
        });
        store.set(
            "postRawTxt",
            JSON.stringify(convertToRaw(this.state.postxt.getCurrentContent()))
        );
    }
    handleAddr(addr = {}) {
        this.setState({
            addrGps: addr,
        });
    }
    // 去重下地址数组
    unique(newArr = [], oldArr = []) {
        let arr = [...newArr, ...oldArr];
        for (let i = 0; i < arr.length; i++) {
            for (let j = i + 1; j < arr.length; j++) {
                if (arr[i].id == arr[j].id) {
                    arr.splice(j, 1);
                    j--;
                }
            }
        }
        return arr;
    }
    async initQiniu() {
        await requireScript("/assets/plupload.full.min.js");
        await requireScript("/assets/qiniu.min.js");

        const qiniupload = new QiniuJsSDK();
        const _Domain = config.cdn;
        const That = this;

        qiniupload.uploader({
            runtimes: "html5, flash, html4",
            browse_button: "uploadImg",
            uptoken_url: config.baseUrl + "/uptoken",
            get_new_uptoken: false,
            unique_names: false,
            save_key: false,
            domain: _Domain,
            max_file_size: "500mb",
            flash_swf_url: "//cdn.staticfile.org/plupload/2.1.8/Moxie.swf",
            max_retries: 3,
            dragdrop: false,
            chunk_size: "4mb",
            auto_start: true,
            init: {
                FilesAdded: function (up, files) {
                    That.setState({
                        imgListLen: That.state.imgListLen + files.length,
                    });
                },
                BeforeUpload: function (up, file) {
                    // 每个文件上传前，处理相关的事情
                    console.log(arguments);
                },
                UploadProgress: (up, file) => {
                    That.setState({
                        upimgState: true,
                        upStateTxt: `上传中(${file.percent}%)`,
                        uploadPercent: file.percent,
                    });
                },
                FileUploaded: async (up, file, info) => {
                    const res = JSON.parse(info);
                    // 检测到是视频格式，进入转换
                    if (file.type.includes("video")) {
                        return That.videoChange(res.key);
                    }
                    // 生成截取图片
                    const imgLink = qiniupload.imageMogr2(
                        {
                            "auto-orient": true, // 布尔值，是否根据原图EXIF信息自动旋正，便于后续处理，建议放在首位。
                            strip: true, // 布尔值，是否去除图片中的元信息
                            thumbnail: "!750x", // 缩放操作参数
                            quality: 70, // 图片质量，取值范围1-100
                        },
                        res.key
                    );
                    const { RGB: bgColor = null } = await getImageAve(
                        config.cdn + res.key
                    );
                    console.warn("📷imageMogr2 ==>", imgLink);
                    console.warn("🌹RGB ==>", bgColor);

                    let arr =
                        That.state.imglist !== null ? That.state.imglist : [];
                    arr.push({
                        type: "image", // 数据类型
                        key: res.key, // 图片原始key
                        src: imgLink, // 图片参数的连接
                        bgColor: bgColor, // 图片颜色平均值
                    });
                    // 存储一份本地
                    store.set("postMedia", JSON.stringify(arr));

                    That.setState(
                        {
                            upimgState: false,
                            uploadPercent: 0,
                            upStateTxt: "",
                            imglist: arr,
                            imgListLen: arr.length,
                        },
                        async () => {
                            try {
                                const data = await getExif(
                                    config.cdn + res.key
                                );
                                if (!data.GPSLongitude) {
                                    return console.warn(
                                        "🌍GPS ==>",
                                        "no GPS data lol"
                                    );
                                }
                                const coord = tfCoord(data); // 从exif拿到坐标
                                const addressRet = await getAddressByCoord(
                                    coord[0],
                                    coord[1]
                                );
                                if (!addressRet || addressRet.status !== 0) {
                                    return console.error(
                                        "地理位置获取失败：" +
                                            JSON.stringify(coord)
                                    );
                                }
                                // pois是返回周边10个地址，address是gps坐标地址
                                const {
                                    result: { pois, address },
                                } = addressRet;
                                const gpsList = this.unique(
                                    this.state.addrList,
                                    pois
                                );
                                That.setState(
                                    {
                                        addrList: gpsList,
                                    },
                                    () => {
                                        store.set(
                                            "gpsList",
                                            JSON.stringify(gpsList)
                                        );
                                    }
                                );
                            } catch (err) {
                                console.warn(err);
                            }
                        }
                    );
                },
                Key: (up, file) => {
                    // 当save_key和unique_names设为false时，该方法将被调用
                    const ext = qiniupload.getFileExtension(file.name);
                    const key = `rhea/tang_${Date.now()}.${ext}`; // 文件名
                    return key;
                },
                Error: (up, err, errTip) => {
                    console.error("上传错误:=====>", errTip);
                },
                UploadComplete: () => {
                    // 队列文件处理完毕后，处理相关的事情
                },
            },
            filters: {
                max_file_size: "500mb",
                prevent_duplicates: true,
                // Specify what files to browse for
                mime_types: [
                    // {title : "Image files", extensions : "jpg,gif,png,jpeg,svg,webp"},
                    // {title : "Video files", extensions : "mov,mp4,avi"},
                ],
            },
        });
    }
    async routerHandle(location) {
        const { search } = location;
        const { dispatch } = this.props;
        if (!!!search) return;

        const { type, id } = getURLParameters(search);
        if (type && type === "edit") {
            dispatch({
                type: "SHOW_TOAST",
                tstmsg: "获取修改ID",
            });
            try {
                const { code, data, msg } = await queryDailyItem(id);
                if (!code || code !== 200)
                    return dispatch({
                        type: "SHOW_TOAST",
                        tstmsg: msg || "获取文章失败",
                    });

                let POSTXT = null;
                if (isJsonString(data.txt)) {
                    POSTXT = EditorState.createWithContent(
                        convertFromRaw(JSON.parse(data.txt))
                    );
                } else {
                    const blocksFromHTML = convertFromHTML(data.txt);
                    const state = ContentState.createFromBlockArray(
                        blocksFromHTML.contentBlocks,
                        blocksFromHTML.entityMap
                    );
                    POSTXT = EditorState.createWithContent(state);
                }

                this.setState({
                    editState: true,
                    postxt: POSTXT,
                    imglist: data.images,
                    editId: data.objectId,
                    role: data.role,
                    catesId: data.cate,
                });
            } catch (err) {
                console.error(err);
            }
        }
    }
    // 转换视频函数
    async videoChange(key) {
        try {
            const pfopRet = await queryPfop(key);
            // 已经上传成功，后台进入转换队列
            if (pfopRet.status === 404) {
                throw new Error(pfopRet.data);
            }
            if (pfopRet.code !== 0) {
                this.setState({
                    upimgState: true,
                    upStateTxt: "转换中...",
                });
            }
            /**
             * 转换中本地存储上传后的视频信息
             * 防止转换过程过久后退出
             * 重新返回页面可以再次查询转换结果
             */
            store.set(
                "pfopStatus",
                JSON.stringify({
                    status: "pfoping",
                    id: pfopRet.id,
                })
            );

            this.checkResult(pfopRet.id);
        } catch (err) {
            console.error("Err: pfop" + err);
        }
    }
    // 定时器查询转换结果函数
    checkResult(id) {
        const { dispatch } = this.props;
        const Interval = window.setInterval(async () => {
            try {
                let result = await getPrefop(id);
                /**
                 * @param {code} -1｜2: 持续转换 0: 成功 3: 失败
                 */
                if (result.code === 3) {
                    //发生错误
                    clearInterval(this.state.intervalFpof);

                    this.setState({
                        upStateTxt: "转换失败",
                        intervalFpof: null,
                    });

                    dispatch({
                        type: "SHOW_TOAST",
                        tstmsg: "转换失败，请刷新重试~",
                    });
                    store.remove("pfopStatus");

                    //本地存储错误信息以便定位，一般是视频太长、太短，或者截图出现了时长不足的原因
                    store.set("pfopErrorCatch", JSON.stringify(result));
                    return;
                }

                if (result.code === 0) {
                    //转换成功，清除interval
                    clearInterval(this.state.intervalFpof);

                    //缩略图数据
                    const excImg = `${result.items[1].key}${config.thumb.w180}`;
                    let arr =
                        this.state.imglist !== null ? this.state.imglist : [];
                    arr.push({
                        type: "video", // 数据类型
                        key: result.items[1].key, // 图片原始key
                        src: excImg, // 图片参数处理后的地址
                        videoKey: result.items[0].key, // 视频原始key
                        videoType: "application/x-mpegURL", // 视频类型，暂时只做hls流媒体m3u8
                    });

                    store.set("postMedia", JSON.stringify(arr));
                    store.remove("pfopStatus");
                    setTimeout(() => {
                        this.setState({
                            upimgState: false,
                            uploadPercent: 0,
                            upStateTxt: "",
                            imglist: arr,
                            intervalFpof: null,
                        });
                    }, 10);
                }
            } catch (err) {
                console.log(err);
                dispatch({
                    type: "SHOW_TOAST",
                    tstmsg: JSON.stringify(err),
                });
            }
        }, 1000);
        this.setState({
            intervalFpof: Interval,
        });
    }
    // 轮询查询转换状态
    prepfop(id) {
        return new Promise(async (resolve, reject) => {
            try {
                let preRet = await getPrefop(id);
                if (preRet.data) {
                    resolve(preRet.data);
                } else {
                    reject({
                        code: -1,
                        msg: "获取prefop异常",
                    });
                }
            } catch (err) {
                reject({
                    code: -1,
                    msg: err,
                });
                console.error("Err: prefop" + err);
            }
        });
    }
    // 预览图片
    handlePreview(idx = 0) {
        const { imglist } = this.state;
        try {
            let imgarr = [];
            imglist.forEach((item) => {
                imgarr.push(imgProtocalTeck(item.src));
            });
            if (window.wx && window.wx.previewImage) {
                window.wx.previewImage({
                    current: imgProtocalTeck(imglist[idx]["src"]),
                    urls: imgarr,
                });
            } else {
                this.v = viewer({
                    urls: imgarr,
                    index: idx,
                });
            }
        } catch (err) {
            window.location.href = config.cdn + img;
        }
    }
    // 预览视频
    previewVideo(videoInfo) {
        const { videoSrc, videoPoster } = videoInfo;
        const { dispatch } = this.props;
        dispatch({
            type: "UPDATE_VIDEOSRC",
            src: videoSrc,
            poster: videoPoster,
        });
    }
    // 删除预览
    removeImg(index) {
        let imglist = Array.from(this.state.imglist);
        imglist.splice(index, 1);
        this.setState({
            imglist: imglist,
            imgListLen: this.state.imglist.length - 1,
        });
        store.set("postMedia", JSON.stringify(imglist));
    }
    handleRole(role) {
        this.setState(
            {
                role: Number(role),
            },
            () => {
                store.set("role", role);
            }
        );
    }
    async submit() {
        const { postxt, role, imglist, addrGps, catesId, watcher } = this.state;
        const { dispatch, history } = this.props;
        const POSTXT = convertToRaw(postxt.getCurrentContent());

        if (!role) {
            return dispatch({
                type: "SHOW_TOAST",
                tstmsg: "需要选一个角色~",
            });
        }

        if (POSTXT.blocks.length === 1 && POSTXT.blocks[0].text === "") {
            return dispatch({
                type: "SHOW_TOAST",
                tstmsg: "文字为空~",
            });
        }
        dispatch({
            type: "SHOW_TOAST",
            tstmsg: "发布中...",
        });

        let postRet = await setPost({
            cate: catesId,
            txt: JSON.stringify(POSTXT),
            role: Number(role),
            images: imglist,
            addrGps: addrGps,
            watcher: watcher,
        });

        if (!postRet || !postRet["code"] || postRet["code"] !== 200) {
            console.error("发布文章异常===>", "发布文章异常");

            setTimeout(() => {
                history.push("/");
            }, 1300);

            return dispatch({
                type: "SHOW_TOAST",
                tstmsg: postRet?.msg ?? "发布文章异常",
            });
        }
        // 发布成功
        dispatch({
            type: "SHOW_TOAST",
            tstmsg: "发布成功 :)",
        });
        // 角色存储在本地
        store.set("role", Number(role));

        // 存储当前选中的类型
        store.set("cate", Number(catesId));

        // 删除相关key
        store.remove("postRawTxt");
        store.remove("postMedia");
        store.remove("gpsList");

        setTimeout(() => {
            history.push("/");
        }, 1300);
    }
    async editSubmit() {
        const { postxt, role, imglist, editId, catesId } = this.state;
        const { dispatch, history } = this.props;
        const POSTXT = convertToRaw(postxt.getCurrentContent());

        if (!role) {
            return dispatch({
                type: "SHOW_TOAST",
                tstmsg: "需要选一个角色~",
            });
        }

        if (POSTXT.blocks.length === 1 && POSTXT.blocks[0].text === "") {
            return dispatch({
                type: "SHOW_TOAST",
                tstmsg: "文字为空~",
            });
        }
        dispatch({
            type: "SHOW_TOAST",
            tstmsg: "修改发布...",
        });
        try {
            let postRet = await updatePost({
                cate: catesId,
                id: editId,
                txt: JSON.stringify(POSTXT),
                role: Number(role),
                images: imglist,
            });
            if (postRet["code"] !== -1) {
                // 发布成功
                dispatch({
                    type: "SHOW_TOAST",
                    tstmsg: "修改成功 :)",
                });
                // 角色存储在本地
                store.set("role", role);
                // 删除相关key
                store.remove("postRawTxt");
                store.remove("postMedia");
                store.remove("gpsList");

                setTimeout(() => {
                    history.push("/dashboard/list");
                }, 1300);
            } else {
                dispatch({
                    type: "SHOW_TOAST",
                    tstmsg: postRet["msg"] || "修改文章异常",
                });
                console.error(err);
            }
        } catch (err) {
            console.error(err);
        }
    }
    handleCates(objectId, idx) {
        this.setState({
            catesIndex: idx,
            catesId: objectId,
        });
    }
    handleTrigg(boolean) {
        this.setState({
            showCatesInput: boolean,
        });
    }
    onKeyupCates(e) {
        this.setState({
            catesNewTxt: e.target.value,
        });
    }
    async handleSubmitCates() {
        let { catesNewTxt = "", catesList = [], catesIndex } = this.state;
        const { dispatch } = this.props;
        if (catesNewTxt === "") {
            return dispatch({
                type: "SHOW_TOAST",
                tstmsg: `不能为空`,
            });
        }
        try {
            const { data } = await setCates(catesNewTxt);
            this.handleTrigg(false);
            catesList.unshift(data);
            catesIndex++;
            this.setState({
                catesList,
                catesIndex,
            });
            console.log(catesList);
        } catch (err) {
            console.error(err);
            dispatch({
                type: "SHOW_TOAST",
                tstmsg: `错误： ${err}`,
            });
        }
    }
    render() {
        const {
            imglist,
            editState,
            rolelist,
            watcherList,
            postxt,
            role,
            uploadPercent = 0,
            addrList,
            catesList,
            catesIndex = 0,
            showCatesInput = false,
            upStateTxt,
            imgListLen,
        } = this.state;
        return (
            <section className="push-cont">
                {imglist.length > 0 && (
                    <ul
                        className={`form-list pic${imglist.length}`}
                        id="form-list"
                    >
                        {imglist.map((item, index) => {
                            return (
                                <li className="item" key={index}>
                                    <img
                                        alt="TANG"
                                        src={`${config.cdn}${item.key}${config.thumb.w180}`}
                                        onClick={this.handlePreview.bind(
                                            this,
                                            index
                                        )}
                                    />
                                    {item.type === "video" && (
                                        <span
                                            className="icon-play"
                                            onClick={this.previewVideo.bind(
                                                this,
                                                {
                                                    videoPoster: item.key,
                                                    videoSrc: item.videoKey,
                                                }
                                            )}
                                        >
                                            <svg
                                                className="icon"
                                                aria-hidden="true"
                                            >
                                                <use xlinkHref="#icon-play"></use>
                                            </svg>
                                        </span>
                                    )}
                                    <span
                                        className="remove"
                                        onClick={this.removeImg.bind(
                                            this,
                                            index
                                        )}
                                    ></span>
                                </li>
                            );
                        })}
                        {!!this.state.upimgState && (
                            <li className="item placehold">
                                <WaveLoading loading={uploadPercent} />
                                <em className="txt">{upStateTxt}</em>
                            </li>
                        )}
                    </ul>
                )}
                {!!(addrList.length > 0) && (
                    <AddrList
                        list={addrList}
                        addr={this.handleAddr.bind(this)}
                    />
                )}
                <div className="editor">
                    <div className="wrap-toolbar">
                        <Toolbar>
                            {(externalProps) => (
                                <>
                                    <BoldButton {...externalProps} />
                                    <ItalicButton {...externalProps} />
                                    <UnderlineButton {...externalProps} />
                                    <CodeButton {...externalProps} />
                                    <HeadlinesButton {...externalProps} />
                                    <BlockquoteButton {...externalProps} />
                                    <BtnUpload
                                        size="small"
                                        id="uploadImg"
                                        style={{
                                            fontSize: "18px",
                                            display: `${
                                                imgListLen < 9
                                                    ? "inline-block"
                                                    : "none"
                                            }`,
                                        }}
                                    />
                                </>
                            )}
                        </Toolbar>
                    </div>
                    <Editor
                        editorState={postxt}
                        onChange={this.handleChange.bind(this)}
                        placeholder="说点什么吧……"
                        plugins={plugins}
                    />
                </div>
                <ul className="tab-sex">
                    {Object.keys(rolelist).map((key, k) => {
                        return (
                            <li
                                key={k}
                                className={`item ${
                                    Number(role) === Number(key) ? "active" : ""
                                }`}
                                onClick={this.handleRole.bind(this, key)}
                            >
                                <span className="txt">{rolelist[key]}</span>
                            </li>
                        );
                    })}
                </ul>
                <section className="wrap-category">
                    <span className="title">分类</span>
                    <ul className="list">
                        {catesList.length === 0 ? (
                            <li className="item">Loading…</li>
                        ) : (
                            catesList.map((cate, idx) => {
                                return (
                                    <li
                                        onClick={() => {
                                            this.handleCates(
                                                cate.objectId,
                                                idx
                                            );
                                        }}
                                        key={idx}
                                        className={`item ${
                                            catesIndex === idx ? "active" : ""
                                        }`}
                                    >
                                        <span className="txt">
                                            #{cate.name}
                                        </span>
                                    </li>
                                );
                            })
                        )}
                        {!showCatesInput && (
                            <li
                                className="item"
                                onClick={() => {
                                    this.handleTrigg(true);
                                }}
                            >
                                <img
                                    className="icon"
                                    src={require("@/static/images/icon-addcate.svg")}
                                    alt="新建分类"
                                />
                            </li>
                        )}
                        {showCatesInput && (
                            <li className="item item-cates">
                                <input
                                    type="text"
                                    className="input-cate"
                                    placeholder="输入名称"
                                    onKeyUp={(e) => {
                                        this.onKeyupCates(e);
                                    }}
                                />
                                <i
                                    className="icon-change"
                                    onClick={() => {
                                        this.handleSubmitCates();
                                    }}
                                ></i>
                            </li>
                        )}
                    </ul>
                </section>
                <Form className="form-public">
                    <Form.Item label="是谁？">
                        <Radio.Group
                            onChange={(val) => {
                                this.setState({
                                    watcher: Number(val),
                                });
                            }}
                            defaultValue="1"
                            direction="horizontal"
                        >
                            {Object.keys(watcherList).map((key, idx) => {
                                return (
                                    <Radio name={`${key}`} key={idx}>
                                        {watcherList[key]["name"]}
                                    </Radio>
                                );
                            })}
                        </Radio.Group>
                    </Form.Item>
                </Form>
                <section className="mod-operation">
                    <button
                        className="btn-submit"
                        type="submit"
                        onClick={
                            !!editState
                                ? this.editSubmit.bind(this)
                                : this.submit.bind(this)
                        }
                    >
                        {!!editState ? "修改" : "发布"}
                    </button>
                </section>
            </section>
        );
    }
}

export default withRouter(Publish);
