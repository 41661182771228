export const reducer = (state, action) => {
    if (!state) return {};

    switch (action.type) {
        case "UPDATE_DAILY":
            return {
                ...state,
                dailylist: action.dailylist,
            };
        case "UPDATE_SYSTEM":
            return {
                ...state,
                system: action.system,
            };
        case "UPDATE_VIDEOSRC":
            return {
                ...state,
                videoSrc: action.src,
                videoPoster: action.poster,
                videoWidth: action.width || null,
                videoHeight: action.height || null,
            };
        case "SHOW_TOAST":
            return {
                ...state,
                isShowToast: true,
                delay: action.delay,
                tstmsg: action.tstmsg,
            };
        case "DELETE_TOAST":
            return {
                ...state,
                isShowToast: false,
                tstmsg: "",
            };
        case "SET_KEEPALIVE":
            return {
                ...state,
                keepAliveDate: action.data,
                listKeepAlive: true,
            };
        case "DEL_KEEPALIVE":
            return {
                ...state,
                keepAliveDate: {},
                listKeepAlive: false,
            };
        default:
            return state;
    }
};
