/**
 * 全局Config配置，引入 @config
 */

let config = {
    cdn: "//static.eyan.life/",
    qqMapKey: "7QJBZ-N4N6J-ALPFH-FRUPA-MOAQO-XAFTX",
    thumb: {
        w180: "?imageView2/1/w/180/h/180",
        w375: "?imageMogr2/auto-orient/thumbnail/375x/strip/quality/70",
        w750: "?imageMogr2/auto-orient/thumbnail/750x/strip/quality/80",
    },
    sentry: {
        dsn: "https://1d9ebf4dc1e8418e8f8d4354ae15a529@o466454.ingest.sentry.io/5480787",
    },
};

const hostname = () => {
    return window.location.hostname;
};

switch (process.env.NODE_ENV) {
    case "development":
        config = Object.assign(config, {
            baseUrl: `http://${hostname()}:8003/api/v2`,
            cdn: "//static.test.eyan.life/",
        });
        break;
    case "production":
        config = Object.assign(config, {
            baseUrl: "https://api.eyan.life/v2.1",
            cdn: "//static.eyan.life/",
        });
        break;
    default:
        config = { ...config };
}
export default config;
