import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { deletePost } from "@/apis";
import config from "@/config";
import { isJsonString } from "@/utils";

class Items extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
        };
    }
    componentDidMount() {
        const { item } = this.props;
        if (isJsonString(item.txt)) {
            let tpl = "";
            JSON.parse(item.txt).blocks.forEach((_Item) => {
                tpl += _Item.text;
            });
            this.setState({
                title: tpl,
            });
        }
    }
    editItem(id) {
        this.props.history.push({
            pathname: "/dashboard",
            search: `?type=edit&id=${id}`,
        });
        // push一个hash到url可以后退
        // const _URL = window.location.href;
        // window.location.href = _URL + _URL.split('?')[1]? '&' : '?' + 'type=edit&id=' + id;
        // 监听hash变化触发不同的操作，譬如后退、切换到其他tab去
        window.onhashchange = function (ev) {
            console.log(ev);
        };
    }
    async deleteItem(id) {
        const { dispatch } = this.props;
        if (!window.confirm("确认要删除吗？")) return;
        try {
            const delRet = await deletePost(id);
            if (!delRet || !delRet.code || delRet.code === -1) {
                return dispatch({
                    type: "SHOW_TOAST",
                    tstmsg: delRet.msg || "删除文章异常",
                });
            }
            dispatch({
                type: "SHOW_TOAST",
                tstmsg: delRet.msg || "删除成功",
            });
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        const { item, index } = this.props;
        return (
            <li className="item" _id={item.objectId}>
                <span
                    className="link"
                    onClick={this.editItem.bind(this, item.objectId)}
                >
                    {isJsonString(item.txt) ? (
                        this.state.title === "" ? (
                            <img
                                src={
                                    item.images[0] &&
                                    config.cdn +
                                        item.images[0].key +
                                        "?imageView2/1/w/25/h/25"
                                }
                                alt=""
                            />
                        ) : (
                            this.state.title
                        )
                    ) : (
                        item.txt
                    )}
                </span>
                <time className="time">({item._date})</time>
                <span className="operate">
                    <svg
                        className="icon"
                        aria-hidden="true"
                        onClick={this.editItem.bind(this, item.objectId)}
                    >
                        <use xlinkHref="#icon-edit"></use>
                    </svg>
                    <svg
                        className="icon"
                        aria-hidden="true"
                        onClick={this.deleteItem.bind(this, item.objectId)}
                    >
                        <use xlinkHref="#icon-delete"></use>
                    </svg>
                </span>
            </li>
        );
    }
}

export default withRouter(Items);
