import React, { Component } from "react";
import { connect } from "react-redux";
import Items from "./Item";
import Pages from "./Pages";
import { mapStateToProps } from "@/store";
import { getLocalTime, getURLParameters } from "@/utils";
import { queryDaily } from "@/apis";

export default class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            rows: 10,
            loadmore: false,
            dailylist: [],
            pagelist: [],
            noresult: false,
            isloading: false,
        };
    }
    componentDidMount() {
        if (window.location.href.includes("page")) {
            const { page } = getURLParameters(window.location.href);
            this.setState(
                {
                    page: page,
                },
                () => {
                    this.getDaily();
                }
            );
        } else {
            this.getDaily();
        }
        window.onhashchange = (ev) => {
            this.setState({
                isloading: true,
            });
            if (ev.newURL.includes("page")) {
                const { page } = getURLParameters(ev.newURL);
                this.setState(
                    {
                        page: page,
                    },
                    () => {
                        this.getDaily();
                    }
                );
            }
        };
    }
    renderPage(Props) {
        const { rows } = this.state;
        const { system } = Props;
        if (!system) return;
        try {
            let { saynum } = system,
                pagesize = Math.ceil(saynum / rows), // 总条数除以拉取总数，得到分页数
                pagesArr = [];

            for (let i = 0; i < pagesize; i++) {
                pagesArr.push(i);
            }
            this.setState({
                pagelist: pagesArr,
            });
        } catch (err) {
            console.error(err);
        }
    }
    async getDaily() {
        const { page, rows } = this.state;
        const { dispatch } = this.props;
        const daily = await queryDaily({
            page: page,
            rows: rows,
        });

        if (!daily.data || daily.code !== 200)
            return console.error(daily.msg || "请求接口异常");

        if (daily.code === -1)
            return this.setState({
                noresult: true,
            });
        const Daily = daily["data"];
        //操作creatAt的UTC时间，换成本地时间
        Daily.forEach((item) => {
            item._date = getLocalTime(item.createdAt)._date;
        });

        this.setState(
            {
                loadmore: false,
                noresult: false,
                dailylist: Daily,
                isloading: false,
            },
            () => {
                // 渲染翻页列表数据
                this.renderPage(this.props);
            }
        );
    }
    render() {
        const {
            page: curpage,
            loadmore,
            dailylist,
            noresult,
            pagelist,
            isloading,
        } = this.state;
        const { dispatch } = this.props;

        if (dailylist.length === 0 || !dailylist || !!loadmore || !!isloading)
            return <div className="loading noreault">加载中…</div>;
        if (!!noresult) return <div className="loading noreault">木有了…</div>;

        return (
            <section className="push-cont">
                <ul className="mod-list">
                    {dailylist &&
                        dailylist.map((item, index) => {
                            return (
                                !!item.display && (
                                    <Items
                                        dispatch={dispatch}
                                        key={item.objectId}
                                        index={index}
                                        item={item}
                                    />
                                )
                            );
                        })}
                </ul>
                <Pages pagelist={pagelist} curpage={curpage} />
            </section>
        );
    }
}

List = connect(mapStateToProps)(List);
