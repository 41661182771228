import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Toast from "./Toast";
import Noauth from "./Noauth";
import { isIphonex } from "@/utils";

const InstallApp = (props) => {
    const [showInstaller, setDisplay] = useState(false);
    const state = useSelector((state) => state);
    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (e) => {
            // 防止 Chrome 67 及更早版本自动显示安装提示
            e.preventDefault();
            let deferredPrompt = e;
            setDisplay(true);
            document
                .querySelector("#installer")
                .addEventListener("click", (e) => {
                    // 隐藏显示 A2HS 按钮的界面
                    setDisplay(false);
                    // 显示安装提示
                    deferredPrompt.prompt();
                    // 等待用户反馈
                    deferredPrompt.userChoice.then((choiceResult) => {
                        // choiceResult => "installed", "dismissed"
                        if (choiceResult.outcome === "accepted") {
                            console.log("User accepted the A2HS prompt");
                            dispatch({
                                type: "SHOW_TOAST",
                                tstmsg: "accepted the A2HS prompt",
                            });
                        } else {
                            console.log("User dismissed the A2HS prompt");
                            dispatch({
                                type: "SHOW_TOAST",
                                tstmsg: "dismissed the A2HS prompt",
                            });
                        }
                        deferredPrompt = null;
                    });
                });
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <section
            className={`wigget-installer ${!!showInstaller ? "show" : ""}`}
        >
            <i className="icon-logo"></i>
            <span className="txt" id="installer">
                点击安装到桌面
            </span>
            <em className="close" onClick={() => setDisplay(false)}></em>
        </section>
    );
};

const Footer = (props) => {
    const { nopeAuth, sword } = props;
    const handleClear = () => {
        if (window.confirm("清除所有本地存储？")) {
            window.localStorage.clear();
            console.warn("清除完毕");
        }
    };

    return (
        <Fragment>
            <footer className={`${isIphonex() ? "iphoneX footer" : "footer"}`}>
                <p>
                    &copy; <span onClick={() => handleClear()}>2017</span> Build
                    with <em>♥</em>, by{" "}
                    <a href="https://note.eyan.life/about">Hoan</a>.
                </p>
            </footer>
            <Toast />
            <Noauth nopeAuth={nopeAuth} sword={sword} />
            <InstallApp />
        </Fragment>
    );
};

export default Footer;
