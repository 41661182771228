import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { createStore, compose } from "redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "@/pages/index";
import Config from "@/config";
import "@/pages/styles/global.scss";
import * as serviceWorker from "@/serviceWorker";
import { reducer } from "@/store/reducer";
import pkg from "../package.json";

Sentry.init({
    dsn: Config.sentry.dsn,
    debug: process.env.NODE_ENV === "development",
    environment: process.env.NODE_ENV,
    release: pkg.version,
    integrations: [new Integrations.BrowserTracing()],
});
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: (action) => {
        return action;
    },
});
const Inject = () => {
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
        return compose(
            window.__REDUX_DEVTOOLS_EXTENSION__(),
            sentryReduxEnhancer
        );
    }

    return null;
};
const store = createStore(reducer, Inject());

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);

serviceWorker.register();
