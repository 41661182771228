import React, { PureComponent, Component, Fragment } from "react";
import { HashRouter, Link } from "react-router-dom";
import "./styles/header.scss";

const qrcode = require("@/static/images/qrcode-min.jpg");
// const IMGBG = require('@/static/images/bg-b01.jpeg');
// const IMGBG = require("@/static/images/bg-a02.jpg");

interface IProps {
    closerp?: any;
    headobj?: IHead;
}
interface IHead {
    bgimg?: string;
    avatar?: string;
    title?: string;
    talks?: string;
    saynum?: number;
}
interface IState {
    rmtalks: boolean;
    showrp: boolean;
}

/*eslint no-useless-constructor: "error"*/
class Packdom extends PureComponent<IProps, IState> {
    // constructor(props: IProps){}
    render() {
        return (
            <section className="mod-layout layout-package">
                <div className="cont">
                    <img src={qrcode} alt="qrcode for Tang" />
                    <span className="close" onClick={this.props.closerp}></span>
                </div>
            </section>
        );
    }
}

class Header extends Component<IProps, IState> {
    state: IState = {
        rmtalks: true,
        showrp: false,
    };

    static defaultProps: IProps = {
        headobj: {
            bgimg: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=",
            avatar: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=",
            title: "成长碎碎念",
            saynum: 0,
            talks: "",
        },
    };
    showpackage(e: any) {
        this.setState({
            showrp: true,
        });
        e.stopPropagation();
    }
    closerp() {
        this.setState({
            showrp: false,
        });
    }

    async componentDidMount() {
        setTimeout(() => {
            //移除说话
            this.setState({
                rmtalks: !this.state.rmtalks,
            });
        }, 5000);
    }

    render() {
        const { headobj } = this.props as any;
        const isPassurl = window.location.href.indexOf("login") !== -1;
        return (
            <Fragment>
                <section
                    className={`head ${isPassurl ? "none" : ""}`}
                    style={{
                        backgroundImage: `url(${headobj.bgimg})`,
                    }}
                >
                    <div className="avatar">
                        <HashRouter>
                            <Link to="/about">
                                <img src={headobj.avatar} alt={headobj.title} />
                            </Link>
                        </HashRouter>
                        {this.state.rmtalks ? (
                            <span className="talks">{headobj.talks}</span>
                        ) : null}
                        <svg
                            className="icon icon-redp"
                            aria-hidden="true"
                            onClick={(e) => {
                                this.showpackage(e);
                            }}
                        >
                            <use xlinkHref="#icon-hongbao"></use>
                        </svg>
                    </div>
                    <span className="name">{headobj.title}</span>
                    <span className="count">{headobj.saynum}条记录</span>
                </section>
                {this.state.showrp ? (
                    <Packdom closerp={() => this.closerp()} />
                ) : null}
            </Fragment>
        );
    }
}

export default Header;
