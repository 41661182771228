import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { EditorState, convertFromRaw } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createHashtagPlugin from "@draft-js-plugins/hashtag";
import ImgComputed from "@/components/ImgWrap";
import { isJsonString } from "@/utils";
import { mapStateToProps } from "@/store";
import { queryDailyItem } from "@/apis";
import config from "@/config";

const linkifyPlugin = createLinkifyPlugin({ target: "_blank" });
const hashtagPlugin = createHashtagPlugin();
const plugins = [hashtagPlugin, linkifyPlugin];

export default class Daily extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            daily: {
                txt: "Loading……",
            },
            postxt: EditorState.createEmpty(),
        };
    }
    async componentDidMount() {
        const { dispatch } = this.props;
        try {
            const id = this.props.match?.params?.id;
            const index = this.props.history?.location?.query?.idx;
            let datacache = {};
            if (this.props.dailylist && this.props.dailylist.length > 0) {
                datacache = this.props.dailylist[index];
                console.warn(
                    "daily data already exist XD",
                    this.props.dailylist[index]
                );
            } else {
                const { code, data } = await queryDailyItem(id);
                if (code !== 200 || Number(code) !== code || !data.display) {
                    return this.props.history.goBack();
                }
                datacache = data;
                console.warn("daily data not exist :(", data);
            }
            this.setState({
                daily: datacache,
            });

            isJsonString(datacache.txt) &&
                this.setState({
                    postxt: EditorState.createWithContent(
                        convertFromRaw(JSON.parse(datacache.txt))
                    ),
                });
        } catch (err) {
            console.error(err);
            dispatch({
                type: "SHOW_TOAST",
                tstmsg: err,
            });
        }
    }
    handleCateList(id) {
        this.props.history.push(`/category/${id}`);
    }
    render() {
        const {
            role,
            txt,
            createdAt,
            addr_gps = null,
            category = {},
            baby,
        } = this.state.daily;
        const { system } = this.props;
        return (
            <div className="page-detail">
                {/* 发布头像 */}
                <span className="hd-avatar">
                    <svg className="icon" aria-hidden="true">
                        <use
                            xlinkHref={`#icon-${
                                role === 2 ? "049-mother" : "050-father"
                            }`}
                        ></use>
                    </svg>
                    {!!system && !!baby && (
                        <img
                            src={`${config.cdn}${system.category[baby]["avatar"]}`}
                            alt={system.category[baby]["name"]}
                            className="watcher"
                        />
                    )}
                </span>

                {/* 发布信息 */}
                <div className="post-info">
                    <time className="items">
                        {new Date(createdAt).toLocaleString()}
                    </time>
                    {!!addr_gps?.title && (
                        <span className="items">{addr_gps.title}</span>
                    )}
                    <span className="items">
                        <em
                            className="cates"
                            onClick={() => {
                                this.handleCateList(category.objectId);
                            }}
                        >
                            {category.name ? `#${category.name}` : ""}
                        </em>
                    </span>
                </div>

                {/* 图片组件 */}
                <div className="img-wrap">
                    <ImgComputed item={this.state.daily} />
                </div>

                {/* 文本内容 */}
                <div className="contain">
                    {isJsonString(txt) ? (
                        <Editor
                            editorState={this.state.postxt}
                            onChange={(val) => {
                                this.setState({ postxt: val });
                            }}
                            readOnly={true}
                            plugins={plugins}
                        />
                    ) : (
                        <section className="txt">{txt}</section>
                    )}
                </div>
            </div>
        );
    }
}

Daily = withRouter(connect(mapStateToProps)(Daily));
