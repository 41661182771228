import React, { PureComponent } from "react";
import { connect } from "react-redux";
import ImgComputed from "./ImgWrap";
import config from "@/config";
import "react-mobile-image-viewer/lib/index.css";

import { EditorState, convertFromRaw } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createHashtagPlugin from "@draft-js-plugins/hashtag";

import { store, isJsonString } from "@/utils";
import { mapStateToProps } from "@/store";
import { DiggItem } from "@/apis";

const linkifyPlugin = createLinkifyPlugin({ target: "_blank" });
const hashtagPlugin = createHashtagPlugin();
const plugins = [hashtagPlugin, linkifyPlugin];

const GIMG = require("@/static/images/icon-gps.svg");

export default class Items extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            postxt: EditorState.createEmpty(),
        };
    }

    static defaultProps = {
        imglist: [],
    };
    componentDidMount() {
        const { item } = this.props;
        isJsonString(item.txt) &&
            this.setState({
                postxt: EditorState.createWithContent(
                    convertFromRaw(JSON.parse(item.txt))
                ),
            });
    }
    async digg(idx, objectId) {
        try {
            const { dispatch, dailylist } = this.props;
            const diggRet = await DiggItem(objectId);

            if (diggRet["code"] !== 200) {
                return dispatch({
                    type: "SHOW_TOAST",
                    tstmsg: "点赞异常~",
                });
            }

            // 数字加1，动态增加class
            dailylist[idx]["digg"]++;
            dailylist[idx]["digged"] = true;
            dispatch({
                type: "UPDATE_DAILY",
                dailylist: Array.from(dailylist),
            });

            // 存储本地数组，排重
            const obj = {
                    id: objectId,
                },
                diggArr = store.get("diggArr")
                    ? JSON.parse(store.get("diggArr"))
                    : [];
            for (var i = 0; i < diggArr.length; i++) {
                if (diggArr[i]["id"] === objectId) return;
            }

            diggArr.push(obj);
            store.set("diggArr", JSON.stringify(diggArr));
        } catch (err) {
            console.error("点赞异常 =====>", err);
        }
    }
    detail(id, index) {
        const { history } = this.props;
        history.push({
            pathname: `/item/${id}`,
            query: {
                idx: index,
            },
        });
    }

    render() {
        const { item, index, system } = this.props;
        return (
            <li className="item" _id={item.objectId}>
                <span
                    className={item.role === 2 ? "role female" : "role"}
                    onClick={() => {
                        this.detail(item.objectId, index);
                    }}
                >
                    <svg className="icon" aria-hidden="true">
                        <use
                            xlinkHref={`#icon-${
                                item.role === 2 ? "049-mother" : "050-father"
                            }`}
                        ></use>
                    </svg>
                    {!!system && (
                        <img
                            src={`${config.cdn}${
                                system.category[item["baby"]]["avatar"]
                            }`}
                            alt={system.category[item["baby"]]["name"]}
                            className="watcher"
                        />
                    )}
                </span>
                <div
                    className="time"
                    onClick={() => {
                        this.detail(item.objectId, index);
                    }}
                >
                    <span className="date">{item._date}</span>
                    <span className="year">{item._year}</span>
                </div>
                <div className="something">
                    {isJsonString(item.txt) ? (
                        <Editor
                            editorState={this.state.postxt}
                            onChange={(val) => {
                                this.setState({ postxt: val });
                            }}
                            readOnly={true}
                            plugins={plugins}
                        />
                    ) : (
                        <p
                            className="txt"
                            onClick={() => {
                                this.detail(item.objectId, index);
                            }}
                        >
                            {item.txt}
                        </p>
                    )}
                    {<ImgComputed item={item} />}
                    {!!item.addr_gps && !!item.addr_gps.title && (
                        <div className="btm-info">
                            <p className="addr">
                                <img
                                    src={GIMG}
                                    alt={item.addr_gps.title}
                                    className="icon"
                                />
                                {item.addr_gps.title}
                            </p>
                        </div>
                    )}
                </div>
                <span
                    className={item.digged ? "conner digged" : "conner"}
                    onClick={() => this.digg(index, item.objectId)}
                >
                    <svg className="icon" aria-hidden="true">
                        <use xlinkHref="#icon-candy"></use>
                    </svg>
                    <em>{item.digg}</em>
                </span>
            </li>
        );
    }
}

Items = connect(mapStateToProps)(Items);
