import React, { Component } from "react";
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "./styles/index.scss";
import "@/static/iconfont.2.5"; // eslint:disable-line
import About from "@/pages/about";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import List from "@/components/List";
import Daily from "@/pages/daily";
import Login from "@/pages/login";
import Dashboard from "@/pages/dashboard";
import Notfound from "@/pages/notfound";
import { mapStateToProps } from "@/store";
import { querySetting } from "@/apis";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fixed: "", // 顶部fixed cls
            nopeAuth: true, // 未授权
            scrollHeight: null,
        };
    }
    async componentDidMount() {
        const { dispatch } = this.props;
        try {
            const ret = await querySetting();
            if (!ret.data || ret.code !== 200)
                return console.error(ret.msg || "请求接口异常");

            this.setState({
                headobj: {
                    bgimg: `${ret["data"].bgimg}?imageMogr2/auto-orient/thumbnail/375/strip/quality/50`,
                    avatar: ret["data"].avatar,
                    title: ret["data"].sitename,
                    saynum: ret["data"].saynum,
                    talks: ret["data"].talks,
                },
                sword: ret["data"].sword,
                aboutinfo: ret["data"].richtext,
            });
            // store存储一份全局设置，需要在其他组件用到
            dispatch({
                type: "UPDATE_SYSTEM",
                system: ret["data"],
            });
        } catch (e) {
            console.error(e);
        }
    }
    // #prop func 子组件设置app的头部cls
    setHeadFix(fixed = false, cls = "") {
        this.setState({
            fixed: fixed ? `fixed ${cls}` : `${cls}`,
        });
    }
    // #prop func 子组件从app拿到页面高度
    getMainHeight() {
        return this.main.offsetHeight;
    }
    // #prop func 子组件设置app的头部cls
    nopeAuth(auth = true) {
        this.setState({
            nopeAuth: auth ? "nope-auth" : "",
        });
    }

    render() {
        const showHead = !window.location.hash.includes("item"); // 个别页面不显示头部
        const { headobj, aboutinfo, sword } = this.state;

        return (
            <section
                className={`${this.state.fixed} ${
                    this.state.nopeAuth ? "nope-auth" : ""
                }`}
                ref={(main) => (this.main = main)}
            >
                {showHead ? <Header headobj={headobj} /> : ""}
                <Router>
                    <Route path="/" exact>
                        <List
                            setHeadFix={this.setHeadFix.bind(this)}
                            getMainHeight={this.getMainHeight.bind(this)}
                        />
                    </Route>
                    <Route path="/about">
                        <About
                            setHeadFix={this.setHeadFix.bind(this)}
                            content={aboutinfo}
                        />
                    </Route>
                    <Route path="/login">
                        <Login setHeadFix={this.setHeadFix.bind(this)} />
                    </Route>
                    <Route path="/dashboard">
                        <Dashboard setHeadFix={this.setHeadFix.bind(this)} />
                    </Route>
                    <Route path="/item/:id" component={Daily} />
                    <Route path="/404">
                        <Notfound setHeadFix={this.setHeadFix.bind(this)} />
                    </Route>
                    {/* <Redirect from='*' to='/404' /> */}
                </Router>
                <Footer nopeAuth={this.nopeAuth.bind(this)} sword={sword} />
            </section>
        );
    }
}

export default App = connect(mapStateToProps)(App);
