import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "@/components/styles/about.scss";

class About extends Component {
    constructor(props) {
        super(props);
    }
    static defaultProps = {
        content: '<div class="loading"></div>',
    };
    componentDidMount() {
        setTimeout(() => {
            this.props.setHeadFix(false, "");
        }, 50);
    }
    render() {
        return (
            <Fragment>
                <section
                    className="containt"
                    dangerouslySetInnerHTML={{ __html: this.props.content }}
                ></section>
            </Fragment>
        );
    }
}

About.propTypes = {
    content: PropTypes.node,
};

export default About;
