import React, { PureComponent } from "react";
import { connect } from "react-redux";
import viewer from "react-mobile-image-viewer";
import "react-mobile-image-viewer/lib/index.css";
import "draft-js/dist/Draft.css";
import { imgProtocalTeck, requireScript } from "@/utils";
import { mapStateToProps } from "@/store";
import config from "@/config";

class Isvideo extends PureComponent {
    // 预览视频
    handlePlayVideo(videoInfo) {
        const { dispatch } = this.props;
        const { videoSrc, videoPoster } = videoInfo;

        dispatch({
            type: "UPDATE_VIDEOSRC",
            src: videoSrc,
            poster: videoPoster,
        });
    }

    render() {
        const { videoInfo } = this.props;

        return (
            <em
                className="icon-play"
                onClick={this.handlePlayVideo.bind(this, videoInfo)}
            >
                <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#icon-play"></use>
                </svg>
            </em>
        );
    }
}

export default class ImgComputed extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            copyTimer: null,
        };
    }
    componentDidMount() {
        this.renderImgs();
    }
    componentDidUpdate() {
        this.renderImgs();
    }
    async renderImgs() {
        if ("loading" in HTMLImageElement.prototype) {
            const images = document.querySelectorAll("img.lazyload");
            images.forEach((img) => {
                img.src = img.dataset.src;
            });
        } else {
            await requireScript("/assets/lazysizes.min.js");
            // Initiate LazySizes (reads data-src & class=lazyload)
            !!window.lazySizes && window.lazySizes.init();
        }
    }
    // 预览图片
    handlePreview(img, idx = 0) {
        const { item = {} } = this.props;
        try {
            const imglist = item.images.map((item) => {
                return imgProtocalTeck(item.src);
            });
            if (window.wx && window.wx.previewImage) {
                window.wx.previewImage({
                    urls: imglist,
                    current: imgProtocalTeck(img),
                });
            } else {
                this.v = viewer({
                    urls: imglist,
                    index: idx,
                });
            }
        } catch (err) {
            console.error(err);
            window.location.href = img;
        }
    }
    handleCopy(TEXT = "") {
        try {
            const { dispatch } = this.props;

            // 动态创建 input 元素
            let aux = document.createElement("input");
            // 获得需要复制的内容
            aux.setAttribute("value", TEXT);
            // 添加到 DOM 元素中
            document.body.appendChild(aux);
            // 执行选中
            aux.select();
            // 执行复制命令
            document.execCommand("copy");
            // 将 input 元素移除
            document.body.removeChild(aux);
            console.log("复制成功：" + TEXT);
            dispatch({
                type: "SHOW_TOAST",
                tstmsg: "复制成功~",
            });
        } catch (err) {
            console.error(err);
        }
    }
    onItemTouchStart(TEXT) {
        this.setState({
            copyTimer: setTimeout(() => {
                this.handleCopy(TEXT);
            }, 2000),
        });
    }
    onItemTouchEnd() {
        clearTimeout(this.state.copyTimer);
        this.setState({
            copyTimer: null,
        });
    }
    render() {
        const { item, dispatch } = this.props;

        if (!item.images) return null;

        let classComputed = "imglist";
        if (item.images && item.images.length === 1) {
            const IMGSRC = `${config.cdn}${item.images[0].key}${config.thumb.w750}`;
            return (
                <div st="只有一张图片" className={classComputed}>
                    <span
                        className="img"
                        style={{
                            backgroundColor: item.images[0].bgColor
                                ? item.images[0].bgColor
                                : "#efefef",
                        }}
                        onTouchStart={this.onItemTouchStart.bind(this, IMGSRC)}
                        onTouchEnd={this.onItemTouchEnd.bind(this)}
                    >
                        <img
                            data-src={IMGSRC}
                            alt="SUPERTANG!"
                            loading="lazy"
                            className="lazyload"
                            onClick={this.handlePreview.bind(this, IMGSRC)}
                        />
                        {item.images[0].type === "video" && (
                            <Isvideo
                                videoInfo={{
                                    videoSrc: `${config.cdn}${item.images[0].videoKey}`,
                                    videoPoster: `${config.cdn}${item.images[0].key}${config.thumb.w750}`,
                                }}
                                dispatch={dispatch}
                            />
                        )}
                    </span>
                </div>
            );
        } else if (item.images && item.images.length === 2) {
            classComputed += " imglistmulti img_50";
        } else if (item.images && item.images.length >= 2) {
            classComputed += " imglistmulti img_33";
        }

        return (
            <div st="多张图片的样式" className={classComputed}>
                {item.images.map((img, idx) => {
                    return (
                        <span
                            className="img"
                            key={idx}
                            style={{
                                backgroundColor: img.bgColor
                                    ? img.bgColor
                                    : "#efefef",
                            }}
                            onTouchStart={this.onItemTouchStart.bind(
                                this,
                                `${config.cdn}${img.key}`
                            )}
                            onTouchEnd={this.onItemTouchEnd.bind(this)}
                        >
                            <img
                                data-src={`${config.cdn}${img.key}${config.thumb.w375}`}
                                alt="SUPERTANG!"
                                loading="lazy"
                                className="lazyload"
                                onClick={this.handlePreview.bind(
                                    this,
                                    `${config.cdn}${img.key}${config.thumb.w750}`,
                                    idx
                                )}
                            />
                            {img.type === "video" && (
                                <Isvideo
                                    videoInfo={{
                                        videoSrc: `${config.cdn}${img.videoKey}`,
                                        videoPoster: `${config.cdn}${img.key}${config.thumb.w375}`,
                                    }}
                                    dispatch={dispatch}
                                />
                            )}
                        </span>
                    );
                })}
            </div>
        );
    }
}

ImgComputed = connect(mapStateToProps)(ImgComputed);
