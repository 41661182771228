import React, { Component } from "react";
import { connect } from "react-redux";
import { mapStateToProps } from "@/store";
import { getAuthread } from "@/apis";
import { store } from "@/utils";

export default class Noauth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authpwd: "",
            btnAuthTxt: "确认",
            subAuthBtn: false,
            noAuth: true,
        };
    }

    async componentDidMount() {
        const { nopeAuth } = this.props;
        const isPassurl =
            window.location.href.indexOf("dashboard") !== -1 ||
            window.location.href.indexOf("login") !== -1 ||
            window.location.href.indexOf("404") !== -1;
        if (isPassurl) {
            this.setState({
                noAuth: false,
            });
            return nopeAuth(false);
        }
        try {
            // LS读取password是否可以看完全部
            if (store.get("isAuth")) {
                nopeAuth(false);
                this.setState({
                    noAuth: false,
                });
                const { password } = JSON.parse(store.get("isAuth"));
                const authRet = await getAuthread(password);

                if (authRet.code && authRet.code === 200) {
                    nopeAuth(false);
                    this.setState({
                        noAuth: false,
                    });
                    store.set("isAuth", JSON.stringify(authRet));
                } else {
                    nopeAuth(true);
                    this.setState({
                        noAuth: true,
                    });
                }
            }
        } catch (err) {
            nopeAuth(true);
            this.setState({
                noAuth: true,
            });
            console.error(err);
        }
    }

    async verifyAuth() {
        const { nopeAuth, dispatch } = this.props;

        try {
            if (!this.state.authpwd || this.state.authpwd === "") {
                return dispatch({
                    type: "SHOW_TOAST",
                    tstmsg: "请输入暗语 :(",
                });
            }

            this.setState({
                btnAuthTxt: "稍等…",
                subAuthBtn: true,
            });

            const authRet = await getAuthread(this.state.authpwd);
            this.setState(
                {
                    btnAuthTxt: "确认",
                    subAuthBtn: false,
                },
                () =>
                    dispatch({
                        type: "CLOSE_TOAST",
                    })
            );

            if (!authRet.code || authRet.code !== 200) {
                return dispatch({
                    type: "SHOW_TOAST",
                    tstmsg: authRet.msg || "系统错误~" + authRet.code,
                });
            }

            this.setState({
                btnAuthTxt: "确认",
                subAuthBtn: false,
                noAuth: false,
            });
            nopeAuth(false);
            store.set("isAuth", JSON.stringify(authRet));
        } catch (err) {
            console.error(err);
            dispatch({
                type: "SHOW_TOAST",
                tstmsg: "请求错误~",
            });
        }
    }
    render() {
        const { sword } = this.props;
        if (!!!this.state.noAuth) return null;

        return (
            <div className="box-pwd">
                <p className="txt">
                    阅读受限，请输入<span className="in">暗语</span>：
                </p>
                <div className="interactive">
                    <input
                        type="password"
                        className="txt"
                        value={this.state.authpwd}
                        onChange={(e) => {
                            this.setState({
                                authpwd: e.target.value,
                            });
                        }}
                        placeholder={sword}
                    />
                    <button
                        type="submit"
                        className={
                            this.state.subAuthBtn ? "disabled sub" : "sub"
                        }
                        onClick={this.verifyAuth.bind(this)}
                        disabled={this.state.subAuthBtn ? true : false}
                    >
                        {this.state.btnAuthTxt}
                    </button>
                </div>
            </div>
        );
    }
}
Noauth = connect(mapStateToProps)(Noauth);
