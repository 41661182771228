import React, { PureComponent } from "react";
import "./styles/notfound.scss";

export default class Notfound extends PureComponent {
    componentDidMount() {
        const { setHeadFix } = this.props;
        setHeadFix(true);
    }
    render() {
        return (
            <div className="mod-notfound">
                <span className="icon"></span>
            </div>
        );
    }
}
