import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { mapStateToProps } from "@/store";
import { setSystem } from "@/apis";

export default class Setting extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            sitename: "",
            avatar: "",
            bgimg: "",
            count: "",
            rolelist: {
                1: "牛粑粑",
                2: "牛麻麻",
            },
        };
    }
    static defaultProps = {
        dispatch: null,
        system: {},
    };
    componentDidMount() {
        const { system } = this.props;
        this.setSystemState(system);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { system } = nextProps;
        this.setSystemState(system);
    }
    setSystemState(state) {
        if (!state || Object.keys(state).length === 0) return;
        this.setState({
            sitename: state.sitename,
            avatar: state.avatar,
            bgimg: state.bgimg,
            count: state.count,
            rolelist: state.role,
        });
    }
    handleRole(e) {
        let rolelist = this.state.rolelist;
        rolelist[e.target.name] = e.target.value;
        this.setState({
            rolelist: Object.assign({}, rolelist),
        });
    }
    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }
    async setsystem() {
        const { dispatch } = this.props;
        try {
            let Sysret = await setSystem({
                sitename: this.state.sitename,
                avatar: this.state.avatar,
                bgimg: this.state.bgimg,
                count: this.state.count,
                role: this.state.rolelist,
            });
            if (!Sysret.code || Sysret.code !== 200) {
                return dispatch({
                    type: "SHOW_TOAST",
                    tstmsg: Sysret.msg || "数据错误，检查后台",
                });
            }
            dispatch({
                type: "SHOW_TOAST",
                tstmsg: "修改成功 :)",
            });
        } catch (err) {
            console.error(err);
        }
    }
    render() {
        const { sitename, avatar, bgimg, count, rolelist } = this.state;
        return (
            <section className="push-cont">
                <dl className="setlist">
                    <dt>站点名：</dt>
                    <dd>
                        <input
                            type="text"
                            name="sitename"
                            value={sitename}
                            onChange={this.handleInput.bind(this)}
                        />
                    </dd>
                    <dt>头像：</dt>
                    <dd>
                        <input
                            type="text"
                            name="avatar"
                            value={avatar}
                            onChange={this.handleInput.bind(this)}
                        />
                    </dd>
                    <dt>背景图：</dt>
                    <dd>
                        <input
                            type="text"
                            name="bgimg"
                            value={bgimg}
                            onChange={this.handleInput.bind(this)}
                        />
                    </dd>
                    <dt>一次拉取几条数据：</dt>
                    <dd>
                        <input
                            type="text"
                            name="count"
                            value={count}
                            onChange={this.handleInput.bind(this)}
                        />
                    </dd>
                    <dt>角色设置：</dt>
                    <dd className="spe">
                        {Object.keys(rolelist).map((key) => {
                            return (
                                <input
                                    type="text"
                                    name={key}
                                    value={rolelist[key]}
                                    key={key}
                                    onChange={(e) => this.handleRole(e)}
                                />
                            );
                        })}
                    </dd>
                </dl>
                <button
                    type="submit"
                    className="btn-submit"
                    onClick={this.setsystem.bind(this)}
                >
                    修改
                </button>
            </section>
        );
    }
}

Setting = connect(mapStateToProps)(Setting);
