/*
 * 封装几个api请求
 */

import request from "../utils/request";

export async function querySetting() {
    return request("/get/?info=system", {
        method: "GET",
    });
}

export async function queryCates() {
    return request("/get/?info=category", {
        method: "GET",
    });
}

export async function queryDaily({ page, rows } = {}) {
    return request("/list", {
        method: "GET",
        params: {
            page,
            rows,
        },
    });
}

export async function queryDailyItem(id) {
    return request(`/item/${id}`, {
        method: "GET",
    });
}

export async function getAuthread(pwd) {
    return request("/authread", {
        method: "GET",
        params: {
            pwd,
        },
    });
}

export async function queryLogin({ username, password } = {}) {
    return request("/login", {
        data: {
            username,
            password,
        },
        // requestType: 'json'
    });
}

/**
 * 设置一个分类，只能唯一
 * @param {String} name 分类名称
 */
export async function setCates(name) {
    return request("/category", {
        data: {
            name: name,
        },
    });
}

export async function DiggItem(id) {
    return request("/digg", {
        data: {
            id,
        },
    });
}

export async function getPrefop(id) {
    return request("/prefop", {
        method: "GET",
        params: {
            id,
        },
    });
}

/**
 * 发布文章
 * @param {String} txt 文章正文
 * @param {Number} role 发布者角色
 * @param {Array} images 发布的图片
 * @param {Object} addrGps 地理位置信息
 */
export async function setPost({
    txt,
    role,
    images = [],
    addrGps = {},
    cate,
    watcher = 1,
} = {}) {
    return request("/item", {
        data: {
            txt,
            role,
            images: JSON.stringify(images),
            addrGps: JSON.stringify(addrGps),
            cate,
            watcher,
        },
    });
}

/**
 * 更新文章
 * @param {Number} id 文章id
 * @param {String} txt 文章正文
 * @param {Number} role 发布者角色
 * @param {Array} images 发布的图片
 */
export async function updatePost({ id, txt, role, images = [], cate } = {}) {
    return request("/update", {
        data: { id, txt, role, images: JSON.stringify(images), cate },
    });
}

/**
 * 删除文章
 * @param {Number} id 文章id
 */
export async function deletePost(id) {
    return request("/delete", {
        method: "GET",
        params: {
            id: id,
        },
    });
}

/**
 * 获取文章详情
 * @param {Number} id 文章id
 */
export async function editPost(id) {
    return request("/article", {
        method: "GET",
        params: {
            id: id,
        },
    });
}

/**
 * 根据视频key触发七牛云的任务队列，异步执行
 * @param {String} srcKey 视频key
 */
export async function queryPfop(srcKey) {
    return request("/pfop", {
        method: "GET",
        params: {
            srcKey: srcKey,
        },
    });
}

/**
 * 系统设置接口
 * @param {String} sitename 站点名
 * @param {String} avatar 头像地址
 * @param {String} bgimg 背景图地址
 * @param {String} count 一次拉取item的数量
 * @param {String} role 角色配置
 */
export async function setSystem(params) {
    return request("/set", {
        data: params,
    });
}

/**
 * 退出登录账号接口
 */
export async function Logout(params) {
    return request("/logout", {
        method: "GET",
    });
}
