import React, { Component } from "react";
import { HashRouter, Link, Route, withRouter } from "react-router-dom";
import Publish from "./Publish";
import List from "./List";
import Setting from "./Setting";

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tablist: [
                {
                    tabIndex: 0,
                    txt: "编辑内容",
                    route: "/dashboard/list",
                },
                {
                    tabIndex: 1,
                    txt: "发布内容",
                    route: "/dashboard",
                },
                {
                    tabIndex: 2,
                    txt: "系统设置",
                    route: "/dashboard/setting",
                },
                {
                    tabIndex: 3,
                    txt: "退出登录",
                    route: "/dashboard/logout",
                },
            ],
        };
    }
    async componentDidMount() {
        const { pathname } = this.props.location;
        const { setTab } = this.props;
        const { tablist: Tab } = this.state;

        let curTab = Tab.find((item) => {
            return item.route === pathname;
        }) || {
            tabIndex: 0,
        };
        setTab(curTab.tabIndex);
    }

    render() {
        const { tablist } = this.state;
        const { cont, setTab } = this.props;
        return (
            <ul className="mod-tabs">
                {tablist.map((item, idx) => {
                    return (
                        <HashRouter key={idx}>
                            <li
                                className={
                                    cont === idx ? "active item" : "item"
                                }
                                onClick={() => setTab(idx)}
                            >
                                <Link to={item.route}>{item.txt}</Link>
                            </li>
                        </HashRouter>
                    );
                })}
            </ul>
        );
    }
}

class TabsContent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <HashRouter>
                <Route path="/dashboard" exact>
                    <Publish dispatch={this.props.dispatch} />
                </Route>
                <Route path="/dashboard/list" component={List} />
                <Route path="/dashboard/setting" component={Setting} />
            </HashRouter>
        );
    }
}

Tabs = withRouter(Tabs);
TabsContent = withRouter(TabsContent);

export { Tabs, TabsContent };
