import React, { PureComponent, useState } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import { mapStateToProps } from "@/store";
import Items from "./Items";
import "./styles/list.scss";
import { getLocalTime, store } from "@/utils";
import { queryDaily } from "@/apis";

class Loading extends PureComponent {
    render() {
        return <div className="loading"></div>;
    }
}

class Loadmore extends PureComponent {
    render() {
        return <li className="loading minload"></li>;
    }
}

class Noresult extends PureComponent {
    render() {
        return <li className="loading noreault">木有了…</li>;
    }
}

const PostBtn = (e) => {
    let history = useHistory();
    function handleLogin(e) {
        const REF = history.location.pathname;
        if (!store.get("token") || !store.get("userInfo")) {
            console.warn("没有登录");
            return history.push({
                pathname: "/login",
                query: {
                    ref: REF,
                },
            });
        }
        history.push({
            pathname: "/dashboard",
            query: {
                ref: REF,
            },
        });
    }
    return (
        <span className="btn-post-index" onClick={() => handleLogin()}>
            <svg className="icon" aria-hidden="true">
                <use xlinkHref="#icon-fabu-"></use>
            </svg>
        </span>
    );
};

class VideoCom extends PureComponent {
    static defaultProps = {
        videoSrc: null,
        videoPoster: null,
    };
    closeVideo() {
        const { dispatch } = this.props;
        dispatch({
            type: "UPDATE_VIDEOSRC",
            src: null,
            poster: null,
        });
    }
    render() {
        const { videoSrc, videoPoster } = this.props;
        if (!videoSrc) return null;

        return (
            <div className="mod-video">
                <Player playsInline poster={videoPoster} autoPlay>
                    <source
                        src={videoSrc}
                        type={this.props.type || "application/x-mpegURL"}
                    />
                </Player>
                <span
                    className="close"
                    onClick={this.closeVideo.bind(this)}
                ></span>
            </div>
        );
    }
}

class List extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            rows: 10,
            loadmore: false, // 底部加载更多
            scrollCout: true, // 锁住加载更多
            upData: false, // 可以更新数据，锁请求
            noresult: false,
            dailylist: [],
            scrollHeight: null,
        };
    }
    static defaultProps = {
        dailylist: [],
        loadmore: false,
        noresult: false,
    };

    componentWillUnmount() {
        const { dispatch } = this.props;
        delete this.state.dailylist;
        // 组件卸载后要取消监听滚动
        window.removeEventListener("scroll", this.getScrolltop);
        dispatch({
            type: "SET_KEEPALIVE",
            data: this.state,
        });
    }
    async componentDidMount() {
        try {
            setTimeout(() => {
                window.addEventListener("scroll", this.getScrolltop);
                this.getScrolltop();
            }, 10);

            const dailyData = await this.getDaily();
            this.setState({
                ...dailyData,
                winHeight: document.documentElement.clientHeight,
            });
        } catch (e) {
            console.error(e);
        }
    }
    getScrolltop = (event) => {
        const scrolltop =
            document.documentElement.scrollTop || document.body.scrollTop;
        const { setHeadFix } = this.props;

        if (scrolltop > 160) {
            //顶部固定
            setHeadFix(true);
            this.setState(
                {
                    scrollTop: scrolltop,
                },
                this.scrollLoad.bind(this, scrolltop)
            );
        } else {
            setHeadFix(false);
            this.setState(
                {
                    scrollTop: scrolltop,
                },
                this.scrollLoad.bind(this, scrolltop)
            );
        }
    };

    scrollLoad(scrolltop) {
        if (
            Number(
                this.state.scrollHeight - this.state.winHeight - scrolltop
            ) <= 100
        ) {
            console.log("到底了，触发加载更多逻辑");
            !!this.state.scrollCout &&
                this.setState(
                    {
                        loadmore: true,
                        scrollCout: false,
                        upData: true,
                    },
                    async () => {
                        this.setState({
                            ...(await this.getDaily()),
                        });
                    }
                );
        }
    }

    async getDaily() {
        /**
         * 从Redux读取keep-alive字段，
         * true的话就恢复原来数据state
         * if (!!this.props.listKeepAlive)
         */
        // !!this.props.listKeepAlive
        const { page, rows } = this.state;
        const { getMainHeight, dispatch } = this.props;

        const daily = await queryDaily({
            page: page,
            rows: rows,
        });

        if (!daily.data || daily.code !== 200)
            return console.error(daily.msg || "请求接口异常");

        if (daily.code === -1)
            return {
                noresult: true,
            };

        // 操作creatAt的UTC时间，换成本地时间
        daily["data"].forEach((item) => {
            item._date = getLocalTime(item.createdAt)._date;
            item._year = getLocalTime(item.createdAt)._year;
        });

        // 操作更新数据，concat数组
        let cacheData = [];
        if (this.state.upData && this.state.dailylist.length !== null) {
            // 把新数组合并到现有数据
            cacheData = [...this.state.dailylist, ...daily["data"]];
        } else {
            cacheData = daily["data"];
        }

        // console.log('Daily数据', cacheData);
        const loaded = {
            page: this.state.page + 1, // 请求的页数+1
            scrollCout: true, // 继续执行下次
            upData: false, // 更新数据
            loadmore: true, // 隐藏加载更多
        };

        // 加载到最后一页去了，没有数据
        if (daily["data"].length < this.state.rows) {
            console.log("最后一页");
            return this.setState({
                loadmore: false,
                noreault: true,
                scrollCout: false,
            });
        }

        // 通过prop的方式注入store
        dispatch({
            type: "UPDATE_DAILY",
            dailylist: cacheData,
        });

        return {
            scrollHeight: getMainHeight(),
            loadmore: false,
            noresult: false,
            dailylist: cacheData,
            ...loaded,
        };
    }

    render() {
        const { dailylist, videoSrc, videoPoster, dispatch, history } =
            this.props;
        const { loadmore, noresult } = this.state;

        if (dailylist.length === 0) return <Loading />;

        return (
            <section className="containt">
                <ul className="dailylist">
                    {dailylist.map((item, index) => {
                        return (
                            <Items
                                item={item}
                                index={index}
                                key={item.objectId}
                                history={history}
                            />
                        );
                    })}
                    {loadmore ? <Loadmore /> : noresult ? <Noresult /> : null}
                </ul>
                <VideoCom
                    videoSrc={videoSrc}
                    videoPoster={videoPoster}
                    dispatch={dispatch}
                />
                <PostBtn />
            </section>
        );
    }
}

export default withRouter(connect(mapStateToProps)(List));
