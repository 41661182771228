import React, { PureComponent } from "react";

export default class Pages extends PureComponent {
    constructor(props) {
        super(props);
    }
    static defaultProps = {
        pagelist: [],
    };
    render() {
        const { pagelist, curpage } = this.props;
        const HREF = window.location.href;
        const URL = HREF.indexOf("?") !== -1 ? HREF.split("?")[0] : HREF;
        return (
            <ul className="mod-page">
                {pagelist.map((pagenum, index) => {
                    return (
                        <li
                            className={
                                Number(curpage) === index + 1 ? "active" : ""
                            }
                            key={index}
                        >
                            <a href={`${URL}?page=${pagenum + 1}`}>
                                {pagenum + 1}
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    }
}
